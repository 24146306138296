<template>
  <b-overlay
    :show="show"
    large
    rounded="lg"
    spinner-variant="primary"
  >
    <form-wizard
      color=" rgba(92, 177, 231, 1) "
      :title="null"
      :subtitle="null"
      shape="square"
      finish-button-text="Submit"
      back-button-text="Back"
      @on-complete="formSubmitted"
    >
      <!-- accoint details tab -->
      <tab-content title="Create a Quote">
        <b-form-group v-slot="{ ariaDescribedby }">
          <b-col
            md="12"
          ><h5 class="mb-1">
            <b>Quote Type</b>
          </h5></b-col>
          <b-col md="12">
            <b-form-radio
              v-model="quoteType"
              :aria-describedby="ariaDescribedby + 'type'"
              name="some-radios-type"
              value="full"
              class="label-checkbox"
              :class="quoteType == 'full' ? 'selected' : ''"
              @change="checkedType()"
            >Full Project Quote</b-form-radio>
          </b-col>
          <b-col
            md="12"
            class="pl-3"
          >
            <div style="padding-bottom: 21px">
              <span>Create a full project quote using a CAD file.</span>
            </div>
            <!-- <b-form-group style="padding-bottom:40px;">
                        <label for="InputHelp" class="title-step1">Test Fit Request for New Quote</label>
                        <v-select v-model="newTfr" label="tfr" :options="option" :reduce="tfr => tfr.id" :disabled="duplicationSelectedOption == 'B' "></v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group> -->
          </b-col>
          <template>
            <b-col md="12">
              <b-form-radio
                v-model="quoteType"
                :aria-describedby="ariaDescribedby + 'type'"
                name="some-radios-type"
                value="itemized"
                class="label-checkbox"
                :class="duplicationSelectedOption == 'itemized' ? 'selected' : ''"
                @change="checkedType()"
              >Itemized Quote</b-form-radio>
            </b-col>
            <b-col
              md="12"
              class="pl-3"
            >
              <span
                style="padding-bottom: 21px"
              >Create a custom itemized quote without a CAD file</span>
              <!-- <b-form-group>
                            <label for="InputHelp" class="title-step1" style="padding-top:21px;">Test Fit Request for New Quote</label>
                            <v-select v-model="tfr" label="tfr" :options="option" :reduce="tfr => tfr.id" :disabled="(duplicationSelectedOption == 'A' )"></v-select>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group> -->
            </b-col>
          </template>
          <b-col
            md="12"
            class="mt-4 mb-1"
          ><h5><b>Duplicate</b></h5></b-col>
          <b-col md="12">
            <b-form-radio
              v-model="duplicationSelectedOption"
              :aria-describedby="ariaDescribedby"
              name="some-radios"
              value="A"
              class="label-checkbox"
              :disabled="disableDuplication"
              :class="duplicationSelectedOption == 'A' ? 'selected' : ''"
              @change="checked()"
            >Duplicate Existing Quote</b-form-radio>
          </b-col>
          <b-col
            md="12"
            class="pl-3"
          >
            <div style="padding-bottom: 21px">
              <span>Use an existing quote as a starting template.
                {{
                  quoteType == "full"
                    ? "You will be able to update the CAD data if needed."
                    : ""
                }}</span>
            </div>
            <b-form-group style="padding-bottom: 21px; margin-bottom: 0px">
              <label
                for="InputHelp"
                class="title-step1"
              >Select a Quote to Duplicate</label>
              <v-select
                v-model="idQuoteSelected"
                label="tfr_name"
                :options="quoteType == 'full' ? quotes : itemized_quotes"
                item-value="id"
                item-text="tfr_name"
                :reduce="(quote) => quote.id"
                :disabled="duplicationSelectedOption == 'B'"
                @input="quoteChange"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
            <!-- <b-form-group style="padding-bottom:40px;">
                        <label for="InputHelp" class="title-step1">Test Fit Request for New Quote</label>
                        <v-select v-model="newTfr" label="tfr" :options="option" :reduce="tfr => tfr.id" :disabled="duplicationSelectedOption == 'B' "></v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group> -->
          </b-col>
          <template>
            <b-col md="12">
              <b-form-radio
                v-model="duplicationSelectedOption"
                :aria-describedby="ariaDescribedby"
                name="some-radios"
                value="B"
                class="label-checkbox"
                :class="duplicationSelectedOption == 'B' ? 'selected' : ''"
                @change="checked()"
              >Start a New Quote</b-form-radio>
            </b-col>
            <b-col
              md="12"
              class="pl-3"
            >
              <span style="padding-bottom: 21px">Create a new quote from scratch.</span>
              <!-- <b-form-group>
                            <label for="InputHelp" class="title-step1" style="padding-top:21px;">Test Fit Request for New Quote</label>
                            <v-select v-model="tfr" label="tfr" :options="option" :reduce="tfr => tfr.id" :disabled="(duplicationSelectedOption == 'A' )"></v-select>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group> -->
            </b-col>
          </template>
        </b-form-group>
      </tab-content>
      <!-- accoint details tab -->
      <tab-content
        v-if="quoteType == 'full'"
        title="Upload Files"
        :before-change="validationForm"
      >
        <validation-observer
          ref="accountRules"
          tag="form"
        >
          <b-row>
            <b-col md="12">
              <validation-provider
                #default="{ errors }"
                name="CSV"
                :rules="{
                  required: duplicationSelectedOption == 'B' || sqftChanged == true,
                }"
              >
                <h4>
                  {{ duplicationSelectedOption == "B" ? "Upload" : "Update" }}
                  CAD Data for {{ tfrNameLabel }}
                </h4>
                <div
                  v-if="sqftChanged == true"
                  class="alert-warning"
                >
                  <span>
                    <UilExclamationTriangle
                      fill="#FF9F43"
                      size="15px"
                    />
                    Project SQFT has changed. Please upload CAD data.</span>
                </div>
                <b-form-group style="padding-top: 21px">
                  <!-- old validation -->
                  <!--  <div style="margin-bottom:22px" class="alert-info" v-if="(((opp.current_base_spec_version != quoteSelected.base_spec_version) && (Boolean(file)) && (duplicationSelectedOption == 'A')))"> -->
                  <div
                    v-if="
                      3 >= Number(quoteSelected.base_spec_version) &&
                        Boolean(file) &&
                        duplicationSelectedOption == 'A'
                    "
                    style="margin-bottom: 22px"
                    class="alert-warning"
                  >
                    <span
                      style="
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 21px;
                        color: #ff9f43;
                      "
                    >Selected quote uses an old base spec version.</span><span
                      style="
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 21px;
                        color: #ff9f43;
                        padding-left: 1px;
                      "
                    >
                      The new quote will use the latest version and may affect
                      pricing.</span>
                  </div>
                  <div
                    v-if="
                      3 > Number(quoteSelected.base_spec_version) &&
                        !Boolean(file) &&
                        duplicationSelectedOption == 'A'
                    "
                    style="margin-bottom: 22px"
                    class="alert-warning"
                  >
                    <span
                      style="
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 21px;
                        color: #ff9f43;
                      "
                    >Selected quote uses an old base spec version.</span><span
                      style="
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 21px;
                        color: #ff9f43;
                        padding-left: 1px;
                      "
                    >
                      The new quote will use the latest version and may affect
                      pricing.</span>
                  </div>
<!--                  Spec sets mismatch alert-->
                  <div
                      v-if="duplicationSelectedOption!=='B'&& revision.spec_swap_set_id!=opp.quotes.find((q)=>q.id==idQuoteSelected).spec_swap_set_id"
                      style="margin-bottom: 6px;
    background-color: #ffecd9 !important;
    height: 95px;"
                      class="alert-warning"
                  >
                    <div class="row py-1">
                      <div class="col-4">
                        <span
                            class="m-1"
                          style="
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 21px;
                        color: #ff9f43;
                      "
                      >Specification Set Mismatch</span></div>
                      <div class="col">
                        <span
                          style="
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 21px;
                        color: #ff9f43;
                        padding-left: 1px;
                      "
                      >
                     Changing the Spec Set when duplicating an estimate will override any conflicting Spec Swaps. The new Spec Set will take priority and replace any existing Spec Swaps. All other items, additions, and cash allowances will be copied from the original quote.</span></div>
                    </div>

                  </div>
                  <label for="InputHelp">CAD Data</label>
                  <small
                    v-if="duplicationSelectedOption == 'A' && sqftChanged == false"
                    class="text-muted"
                  >
                    optional</small>
                  <b-form-file
                    v-model="file"
                    :state="duplicationSelectedOption == 'B' ? !errors[0] : null"
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                    accept=".csv"
                  />
                  <small
                    v-if="duplicationSelectedOption == 'A' && sqftChanged == false"
                    class="text-muted"
                  >This will overwrite data from the previous quote. If left empty, this
                    quote will use the same CAD data and takeoffs as previous
                    quote.</small>
                  <br>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <label for="InputHelp">Drywall Takeoff (W1)</label>
                <b-input-group append="LF">
                  <b-form-input
                    v-model="drywall"
                    type="number"
                    placeholder="ex.100.50"
                    :readonly="readonlyInput"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <label for="InputHelp">Glazing Takeoff (G1)</label>
                <b-input-group append="LF">
                  <b-form-input
                    v-model="glazing"
                    type="number"
                    placeholder="ex.100.50"
                    :readonly="readonlyInput"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <label for="InputHelp">Demising Wall Takeoff (W2)</label>
                <small class="text-muted"> optional</small>
                <b-input-group append="LF">
                  <b-form-input
                    v-model="demising"
                    type="number"
                    placeholder="ex.100.00"
                    :readonly="readonlyInput"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group>
                <label for="InputHelp">Demolition Reference Image</label>
                <small class="text-muted"> optional</small>
                <b-input-group>
                  <b-input-group-prepend is-text>
                    <feather-icon icon="LinkIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="demolitionReferenceImage"
                    type="text"
                    placeholder="Demolition Reference Image URL"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group>
                <label for="InputHelp">Space Planner Notes</label>
                <small class="text-muted"> optional</small>
                <b-form-textarea
                  id="textarea-default"
                  v-model="plannerNotes"
                  placeholder="Add notes for quote reviewer"
                  rows="3"
                  :state="plannerNotes.length <= maxChar"
                  :class="plannerNotes.length > maxChar ? 'text-danger' : ''"
                />
                <small
                  class="textarea-counter-value float-right"
                  :class="plannerNotes.length > maxChar ? 'bg-danger' : ''"
                >
                  <span class="char-count">{{ plannerNotes.length }}</span> /
                  {{ maxChar }}
                </small>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>
      <tab-content v-if="quoteType != 'full'">
        <b-form-group>
          <label for="InputHelp">Space Planner Notes</label>
          <small class="text-muted"> optional</small>
          <b-form-textarea
            id="textarea-default"
            v-model="plannerNotes"
            placeholder="Add notes for quote reviewer"
            rows="3"
            :state="plannerNotes.length <= maxChar"
            :class="plannerNotes.length > maxChar ? 'text-danger' : ''"
          />
          <small
            class="textarea-counter-value float-right"
            :class="plannerNotes.length > maxChar ? 'bg-danger' : ''"
          >
            <span class="char-count">{{ plannerNotes.length }}</span> /
            {{ maxChar }}
          </small>
        </b-form-group>
      </tab-content>
      <!-- personal details tab -->
      <tab-content
        v-if="quoteType == 'full'"
        title="Review & Submit"
        :before-change="validationFormInfo"
        style="height: 500px; overflow: hidden auto"
      >
        <validation-observer
          ref="infoRules"
          tag="form"
        >
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              <h4 class="mb-0 font-weight-bolder py-1">
                Furniture, Fixtures & Equipment
              </h4>
              <b-table
                v-if="ffe.length > 0"
                responsive="sm"
                :fields="fieldsFFE"
                :items="ffe"
              />
              <h4 v-else>
                No data to display.
              </h4>
            </b-col>
            <b-col
              cols="12"
              class="mb-2"
            >
              <h4 class="mb-0 font-weight-bolder py-1">
                Construction
              </h4>
              <b-table
                v-if="construction.length > 0"
                responsive="sm"
                :fields="fieldsConstruction"
                :items="construction"
              />
              <h4 v-else>
                No data to display.
              </h4>
            </b-col>
            <b-col
              cols="12"
              class="mb-2"
            >
              <h4 class="mb-0 font-weight-bolder py-1">
                Construction Takeoffs
              </h4>
              <b-table
                v-if="takeoffs.length > 0"
                responsive="sm"
                :fields="fieldsTakeoffs"
                :items="takeoffs"
              />
              <h4 v-else>
                No data to display.
              </h4>
            </b-col>
            <b-col
              cols="12"
              class="mb-2"
            >
              <h4 class="mb-0 font-weight-bolder py-1">
                AV/IT
              </h4>
              <b-table
                v-if="avit.length > 0"
                responsive="sm"
                :fields="fieldsAvit"
                :items="avit"
              />
              <h4 v-else>
                No data to display.
              </h4>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>
    </form-wizard>
    <template #overlay>
      <div class="text-center">
        <lottie-animation path="calculating.json" />
        <h1>Please wait...</h1>
      </div>
    </template>
  </b-overlay>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import { UilExclamationTriangle } from '@iconscout/vue-unicons'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormFile,
  BCardText,
  BInputGroup,
  BInputGroupPrepend,
  BTable,
  BOverlay,
  BFormTextarea,
  BFormRadio,
} from 'bootstrap-vue'
import { required } from '@validations'
import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue'
import vSelect from 'vue-select'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormFile,
    BCardText,
    BTable,
    BInputGroup,
    BInputGroupPrepend,
    LottieAnimation,
    BOverlay,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BFormTextarea,
    BFormRadio,
    vSelect,
    UilExclamationTriangle,
  },
  props: ['opp', 'revision'],
  data() {
    return {
      userName: localStorage.getItem('name'),
      show: false,
      glazing: '',
      demising: '',
      drywall: '',
      sqft: '',
      label: '',
      quoteId: 0,
      file: null,
      required,
      parsedCSV: [],
      location: '',
      opportunityID: '',
      sf_opportunity_id: '',
      avit: [],
      construction: [],
      ffe: [],
      errors: [],
      plannerNotes: '',
      tfr: '',
      newTfr: '',
      idQuoteSelected: '',
      quoteSelected: [],
      quotes: [],
      itemized_quotes: [],
      demolitionReferenceImage: '',
      takeoffs: [],
      maxChar: 1000,
      fieldsAvit: [
        {
          key: 'count',
          sortable: true,
        },
        {
          key: 'uid',
          label: 'UID',
          sortable: false,
        },
        {
          key: 'name',
          label: 'Name',
          sortable: false,
        },
      ],
      fieldsConstruction: [
        {
          key: 'count',
          sortable: true,
        },
        {
          key: 'uid',
          label: 'UID',
          sortable: false,
        },
        {
          key: 'name',
          label: 'Name',
          sortable: false,
        },
      ],
      fieldsFFE: [
        {
          key: 'count',
          sortable: true,
        },
        {
          key: 'uid',
          label: 'UID',
          sortable: false,
        },
        {
          key: 'name',
          label: 'Name',
          sortable: false,
        },
      ],
      fieldsTakeoffs: [
        {
          key: 'count',
          label: 'Count',
          sortable: true,
        },
        {
          key: 'linetype',
          label: 'LINETYPE',
          sortable: false,
        },
        {
          key: 'area',
          label: 'AREA',
          sortable: false,
        },
        {
          key: 'length',
          label: 'Length',
          sortable: false,
        },
      ],
      duplicationSelectedOption: 'B',
      quoteType: 'full',
      disableDuplication: false,
      sqftChanged: false,
    }
  },
  computed: {
    readonlyInput() {
      if (this.duplicationSelectedOption == 'A') {
        if (this.file) {
          return false
        }
        return true
      }
      return false
    },
    tfrNameLabel() {
      if (this.duplicationSelectedOption == 'A') {
        const label = this.option
          .filter(obj => obj.id == this.newTfr)
          .map(obj => obj.tfr)

        return label.toString()
      }
      const label = this.option
        .filter(obj => obj.id == this.tfr)
        .map(obj => obj.tfr)

      return label.toString()
    },

  },
  created() {
    // get quotes from all tfrs
    this.opp.quotes = this.opp.tfrs
        .filter(tfr => tfr.quote) // Filter out tfrs that have a quote
        .map(tfr => ({
          ...tfr.quote,
          spec_swap_set_id: tfr.spec_swap_set_id
        })); // Map to get only the quotes with the new property
  },
  beforeMount() {
    this.sqftChanged = false
    this.sqft = this.revision.rentable_sqft
    this.location = this.opp.geography
    this.opportunityID = this.opp.id
    this.quotes = this.opp.quotes.filter(q => q.is_itemized != 1)
    this.itemized_quotes = this.opp.quotes.filter(i => i.is_itemized == 1)
    this.option = this.opp.tfrs
    this.sf_opportunity_id = this.opp.sf_opportunity_id
    this.duplicationSelectedOption = 'B'
    this.idQuoteSelected = ''
    this.newTfr = ''
    this.tfr = this.revision.id
    this.demising = ''
    this.drywall = ''
    this.glazing = ''
    this.disableDuplication = true
    if (this.quotes.length > 0) {
      this.duplicationSelectedOption = 'A'
      this.idQuoteSelected = this.quotes[0].id
      this.newTfr = this.revision.id
      const quote = this.idQuoteSelected
      this.quoteSelected = this.quotes.find(e => e.id == quote)
      this.tfr = ''
      this.demising = this.quoteSelected.w2
      this.drywall = this.quoteSelected.w1
      this.glazing = this.quoteSelected.g1
      this.disableDuplication = false
      if (this.quoteSelected.sqft != this.sqft) {
        this.sqftChanged = true
      }
    } else {
      this.duplicationSelectedOption = 'B'
      this.idQuoteSelected = ''
      this.newTfr = ''
      this.tfr = this.revision.id
      this.demising = ''
      this.drywall = ''
      this.glazing = ''
      if (this.itemized_quotes.length == 0) {
        this.disableDuplication = true
      }
    }
  },
  methods: {
    formSubmitted() {
      if (this.quoteType == 'full') {
        if (this.duplicationSelectedOption == 'A') {
          const formData = new FormData()
          formData.append('file', this.file)
          formData.append('w2', this.demising)
          formData.append('w1', this.drywall)
          formData.append('g1', this.glazing)
          formData.append('sqft', this.sqft)
          formData.append('location', this.location)
          formData.append('opportunityID', this.opportunityID)
          formData.append('plannerNotes', this.plannerNotes)
          formData.append('tfr_id', this.tfr)
          formData.append('userName', this.userName)
          formData.append('demolitionReferenceImage', this.demolitionReferenceImage)
          formData.append('newTfr', this.newTfr)
          formData.append('oldQuoteId', this.idQuoteSelected)
          this.show = true
          this.$http
            .post('/quote/duplicate-csv', formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            })
            .then(response => {
              this.showToast('success', 'Quote Created')
              const quoteId = response.data.id
              this.$router.push(`/opportunities/${this.sf_opportunity_id}/${quoteId}`)
            })
            .catch(() => {
              this.show = false
              this.showToast('danger', 'Error')
            })
        } else {
          const formData = new FormData()
          formData.append('file', this.file)
          formData.append('w2', this.demising)
          formData.append('w1', this.drywall)
          formData.append('g1', this.glazing)
          formData.append('sqft', this.sqft)
          formData.append('location', this.location)
          formData.append('opportunityID', this.opportunityID)
          formData.append('plannerNotes', this.plannerNotes)
          formData.append('tfr_id', this.tfr)
          formData.append('userName', this.userName)
          formData.append('demolitionReferenceImage', this.demolitionReferenceImage)
          this.show = true
          this.$http
            .post('/quote-calculations', formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            })
            .then(response => {
              this.showToast('success', 'Quote Created')
              const quoteId = response.data.id
              this.$router.push(`/opportunities/${this.sf_opportunity_id}/${quoteId}`)
            })
            .catch(() => {
              this.show = false
              this.showToast('danger', 'Error')
            })
        }
      } else if (this.duplicationSelectedOption == 'A') {
        const formData = new FormData()
        formData.append('sqft', this.sqft)
        formData.append('location', this.location)
        formData.append('opportunityID', this.opportunityID)
        formData.append('plannerNotes', this.plannerNotes)
        formData.append('tfr_id', this.tfr)
        formData.append('userName', this.userName)
        formData.append('newTfr', this.newTfr)
        formData.append('oldQuoteId', this.idQuoteSelected)
        this.show = true
        this.$http
          .post('/quote/duplicate', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(response => {
            this.showToast('success', 'Quote Created')
            const quoteId = response.data.id
            this.$router.push(`/opportunities/${this.sf_opportunity_id}/${quoteId}`)
          })
          .catch(() => {
            this.show = false
            this.showToast('danger', 'Error')
          })
      } else {
        const formData = new FormData()
        formData.append('sqft', this.sqft)
        formData.append('location', this.location)
        formData.append('opportunityID', this.opportunityID)
        formData.append('plannerNotes', this.plannerNotes)
        formData.append('tfr_id', this.tfr)
        formData.append('userName', this.userName)
        this.show = true
        this.$http
          .post('/quote/itemized-quote', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(response => {
            this.showToast('success', 'Quote Created')
            const quoteId = response.data.id
            window.location.href = `/opportunities/${this.sf_opportunity_id}/${quoteId}/itemized-edit`
            // this.$router.push(
            //   `/opportunities/${this.sf_opportunity_id}/${quoteId}/itemized-edit`
            // );
          })
          .catch(() => {
            this.show = false
            this.showToast('danger', 'Error')
          })
      }
    },

    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(success => {
          if (success) {
            if (this.duplicationSelectedOption == 'A' && !this.file) {
              this.show = true
              const formData = new FormData()
              formData.append('newTfr', this.newTfr)
              formData.append('oldQuoteId', this.idQuoteSelected)
              formData.append('file', this.file)
              formData.append('w2', this.demising)
              formData.append('w1', this.drywall)
              formData.append('g1', this.glazing)
              formData.append('sqft', this.sqft)
              formData.append('location', this.location)
              formData.append('opportunityID', this.opportunityID)
              formData.append('plannerNotes', this.plannerNotes)
              formData.append('tfr_id', this.tfr)
              formData.append('userName', this.userName)
              formData.append('demolitionReferenceImage', this.demolitionReferenceImage)
              this.$http
                .post('/quote/duplicate', formData, {
                  headers: {
                    'Content-Type': 'multipart/form-data',
                  },
                })
                .then(response => {
                  this.showToast('success', 'Quote Duplicated')
                  const quoteId = response.data.id
                  this.$router.push(
                    `/opportunities/${this.sf_opportunity_id}/${quoteId}`,
                  )
                })
                .catch(error => {
                  this.show = false
                  this.showToast('danger', error.response.data.message)
                  reject()
                })
            } else {
              const formData = new FormData()
              formData.append('file', this.file)
              formData.append('w2', this.demising)
              formData.append('w1', this.drywall)
              formData.append('g1', this.glazing)
              formData.append('sqft', this.sqft)
              formData.append('location', this.location)
              this.$http
                .post('/csv-review', formData, {
                  headers: {
                    'Content-Type': 'multipart/form-data',
                  },
                })
                .then(response => {
                  this.show = false
                  this.parsedCSV = response.data
                  this.avit = this.parsedCSV.avit
                  this.construction = this.parsedCSV.construction
                  this.ffe = this.parsedCSV.ffe
                  this.takeoffs = this.parsedCSV.takeoffs
                  resolve(true)
                })
                .catch(error => {
                  this.show = false
                  this.showToast('danger', error.response.data.message)
                  reject()
                })
            }
          } else {
            reject()
          }
        })
      })
    },
    checkQuoteProcess() {
      if (this.duplicationSelectedOption == 'A') {
        this.demising = this.quoteSelected.w2
        this.drywall = this.quoteSelected.w1
        this.glazing = this.quoteSelected.g1
        return true
      }
      this.demising = ''
      this.drywall = ''
      this.glazing = ''
      return true
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    checked() {
      if (this.duplicationSelectedOption == 'A') {
        if (this.quoteType == 'full') {
          this.idQuoteSelected = this.quotes[0].id
          this.newTfr = this.revision.id
          const quote = this.idQuoteSelected
          this.quoteSelected = this.quotes.find(e => e.id == quote)
          this.tfr = ''
          this.demising = this.quoteSelected.w2
          this.drywall = this.quoteSelected.w1
          this.glazing = this.quoteSelected.g1
        } else {
          this.idQuoteSelected = this.itemized_quotes[0].id
          this.newTfr = this.revision.id
          const quote = this.idQuoteSelected
          this.quoteSelected = this.itemized_quotes.find(e => e.id == quote)
          this.tfr = ''
        }
      } else {
        this.idQuoteSelected = ''
        this.newTfr = ''
        this.tfr = this.revision.id
        this.demising = ''
        this.drywall = ''
        this.glazing = ''
      }
    },
    checkedType() {
      if (this.quoteType == 'full') {
        this.idQuoteSelected = this.quotes[0].id
        const quote = this.idQuoteSelected
        this.quoteSelected = this.quotes.find(e => e.id == quote)
        this.tfr = ''
        this.demising = this.quoteSelected.w2
        this.drywall = this.quoteSelected.w1
        this.glazing = this.quoteSelected.g1
        this.disableDuplication = false
      } else {
        // insert validation to find itemized quotes
        if (this.itemized_quotes.length != 0) {
          this.newTfr = this.revision.id
          this.tfr = this.revision.id
          this.idQuoteSelected = this.itemized_quotes[0].id
          this.newTfr = this.revision.id
          const quote = this.idQuoteSelected
          this.quoteSelected = this.itemized_quotes.find(e => e.id == quote)
          this.disableDuplication = false
          this.duplicationSelectedOption = 'A'
        } else {
          this.tfr = this.revision.id
          this.idQuoteSelected = ''
          this.disableDuplication = true
          this.duplicationSelectedOption = 'B'
        }
      }
    },
    quoteChange(quote) {
      if (this.quoteType == 'full') {
        this.quoteSelected = this.quotes.find(e => e.id == quote)
        this.demising = this.quoteSelected.w2
        this.drywall = this.quoteSelected.w1
        this.glazing = this.quoteSelected.g1
        this.sqftChanged = false
        if (this.quoteSelected.sqft != this.sqft) {
          this.sqftChanged = true
        }
      } else {
        this.quoteSelected = this.itemized_quotes.find(e => e.id == quote)
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";

.label-checkbox {
  font-weight: 600;
}

.selected label {
  color:  rgba(92, 177, 231, 1) ;
}

#modal-duplication .modal .modal-footer {
  display: none;
}

.modal-body {
  padding: 0;
}

#modal-duplication .wizard-nav.wizard-nav-pills {
  display: none;
}

.title-step1 {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
}
.alert-warning {
  width: 100%;
  background: linear-gradient(0deg, rgba(255, 159, 67, 0.12), rgba(255, 159, 67, 0.12)),
  #ffffff;
  border-radius: 4px;
  height: 39px;
  display: flex;
  align-items: center;
  margin-bottom: unset;
}

.alert-info {
  width: 100%;
  border-radius: 4px;
  height: 39px;
  display: flex;
  align-items: center;
  margin-bottom: unset;
}

.alert-info > span {
  font-weight: 700;
  font-size: 14px;
  padding-left: 2%;
}

.alert-warning > span {
  color: #ff9f43;
  font-weight: 700;
  font-size: 14px;
  padding-left: 2%;
}
</style>
